// Styles for Header
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.opened {
  position: absolute;
}
