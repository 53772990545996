@import 'index';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-primary {
  background: linear-gradient(
    90deg,
    #ca0000 0%,
    #9d0909 0.01%,
    #ca0000 50%,
    #491919 100%
  );
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.12) !important;
  border-radius: 8px !important;

  &:hover {
    background: linear-gradient(
      90deg,
      rgba(202, 0, 0, 0.9) 0%,
      rgba(157, 9, 9, 0.9) 0.01%,
      rgba(202, 0, 0, 0.9) 50%,
      rgba(73, 25, 25, 0.9) 100%
    );
  }
}

.bg-primary-gradient {
  background: linear-gradient(
    90deg,
    #ca0000 0%,
    #9d0909 0.01%,
    #ca0000 50%,
    #491919 100%
  );
}
